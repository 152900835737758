import React from "react"
import JsonLd from "./jsonld"
import { useStaticQuery, graphql } from "gatsby"

export default function ContactJson() {
  const { site } = useStaticQuery(query)
  const {
    legalName,
    telephone,
    addressStreet,
    addressCity,
    addressPostal,
    lsDe,
    lsJga,
    lsReisen,
    facebook,
    twitter,
    instagram,
    youtube,
    siteUrl,
    fundingDate,
    image,
  } = site.siteMetadata
  return (
    <JsonLd>
      {{
        "@context": "https://schema.org",
        "@type": "Organization",
        url: `${siteUrl}`,
        sameAs: [
          `${lsDe}`,
          `${lsJga}`,
          `${lsReisen}`,
          `${twitter}`,
          `${instagram}`,
          `${youtube}`,
          `${facebook}`,
        ],
        image: `${siteUrl}${image}`,
        availableLanguage: ["German", "Spanish", "English"],
        founder: {
          "@type": "Person",
          name: "Natalia Pacheco",
        },
        legalName: `${legalName}`,
        foundingDate: `${fundingDate}`,
        address: {
          "@type": "PostalAddress",
          addressLocality: `${addressCity}`,
          postalCode: `${addressPostal}`,
          streetAddress: `${addressStreet}`,
        },
        email: "info(at)latinsalsa.de",
        contactPoint: {
          "@type": "ContactPoint",
          name: "Natalia Pacheco",
          email: "natalia(at)latinsalsa.de",
          telephone: `${telephone}`,
        },
        member: [
          {
            "@type": "Organization",
          },
        ],
        alumni: [
          {
            "@type": "Person",
            name: "Natalia Pacheco",
          },
        ],
        name: "LatinSalsaTours.com",
        telephone: `${telephone}`,
      }}
    </JsonLd>
  )
}

const query = graphql`
  query ContactJson {
    site {
      siteMetadata {
        image
        legalName
        siteUrl
        telephone
        lsDe
        lsJga
        lsReisen
        facebook
        twitter
        instagram
        youtube
        addressStreet
        addressCity
        addressPostal
        fundingDate
      }
    }
  }
`
