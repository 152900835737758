import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import {
  AiOutlineYoutube,
  AiOutlineTwitter,
  AiOutlineInstagram,
  AiFillFacebook,
} from "react-icons/ai"

export default function BigSocial() {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          facebook
          instagram
          twitter
          youtube
        }
      }
    }
  `)
  return (
    <Container className="container-fluid py-4 text-center">
      <h2 className="display-4">Social</h2>
      <Row>
        <div className="col-sm-6 col-lg-3 py-4">
          <div className="lc-block mb-5 display-4">
            <a
              href={data.site.siteMetadata.facebook}
              className="text-dark text-decoration-none"
              target="_blank"
              rel="noreferrer"
              aria-label="LatinSalsa Facebook Page"
            >
              <AiFillFacebook />
            </a>
          </div>

          <div className="lc-block mb-4">
            <div>
              <p>
                <a
                  href={data.site.siteMetadata.facebook}
                  className="h3 text-dark text-decoration-none"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="LatinSalsa Facebook Page"
                >
                  Facebook
                </a>
              </p>
            </div>
          </div>
          <div className="lc-block col-2 offset-5 border-bottom border-2 border-dark">
            <div></div>
          </div>
        </div>

        <div className="col-sm-6 col-lg-3 py-4">
          <div className="lc-block mb-5 display-4">
            <a
              href={data.site.siteMetadata.youtube}
              className="text-dark text-decoration-none"
              target="_blank"
              rel="noreferrer"
              aria-label="LatinSalsa Videos on Youtube"
            >
              <AiOutlineYoutube />
            </a>
          </div>

          <div className="lc-block mb-4">
            <div>
              <p>
                <a
                  href={data.site.siteMetadata.youtube}
                  className="h3 text-dark text-decoration-none"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="LatinSalsa Videos on Youtube"
                >
                  Youtube
                </a>
              </p>
            </div>
          </div>
          <div className="lc-block col-2 offset-5 border-bottom border-2 border-dark">
            <div></div>
          </div>
        </div>

        <div className="col-sm-6 col-lg-3 py-4">
          <div className="lc-block mb-5 display-4">
            <a
              href={data.site.siteMetadata.twitter}
              className="text-dark text-decoration-none"
              target="_blank"
              rel="noreferrer"
              aria-label="LatinSalsa Twitter Account"
            >
              <AiOutlineTwitter />
            </a>
          </div>

          <div className="lc-block mb-4">
            <div>
              <p>
                <a
                  href={data.site.siteMetadata.twitter}
                  className="h3 text-dark text-decoration-none"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="LatinSalsa Twitter Account"
                >
                  Twitter
                </a>
              </p>
            </div>
          </div>
          <div className="lc-block col-2 offset-5 border-bottom border-2 border-dark">
            <div></div>
          </div>
        </div>

        <div className="col-sm-6 col-lg-3 py-4">
          <div className="lc-block mb-5 display-4">
            <a
              href={data.site.siteMetadata.instagram}
              className="text-dark text-decoration-none"
              target="_blank"
              rel="noreferrer"
              aria-label="LatinSalsa Instagram Account"
            >
              <AiOutlineInstagram />
            </a>
          </div>

          <div className="lc-block mb-4">
            <div>
              <p>
                <a
                  href={data.site.siteMetadata.instagram}
                  className="h3 text-dark text-decoration-none"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="LatinSalsa Instagram Account"
                >
                  Instagram
                </a>
              </p>
            </div>
          </div>
          <div className="lc-block col-2 offset-5 border-bottom border-2 border-dark">
            <div></div>
          </div>
        </div>
      </Row>
    </Container>
  )
}
