import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Container from "react-bootstrap/Container"
import Layout from "../components/layout"

import ContactForm from "../components/contact/contactForm"
import BigSocial from "../components/bigSocial"
import LsFamily from "../components/contact/lsfamily"
import WhatsApp from "../components/contact/whatsapp"
import Newsletter from "../components/newsletter"
import Seo from "../components/Seo/seo"
import ContactJson from "../components/Seo/contactJson"

export default function Contact() {
  const img = (
    <StaticImage src="../images/natalia-contact.jpg" alt="Natalia Pacheco" />
  )
  return (
    <Layout>
      <Seo
        title="Contact Page"
        description="Get answers to all your questions about the tour in Costa Rica - via Whatsapp, mail or with our contact form."
        image={img}
      />
      <ContactJson />
      <Container>
        <h1 className="display-1 text-center my-5 lstitle text-uppercase">
          Contact
        </h1>
        <h2 className="fw-lighter display-5 text-center mb-5">
          Write or call and get your answeres! We are here to help!
        </h2>
        <div className="row">
          <div className="col-md-8">
            <ContactForm />
          </div>
          <div className="col-md-3 d-sm-none d-md-block">
            <figure>
              <StaticImage
                src="../images/natalia-contact.jpg"
                alt="Natalia Pacheco"
              />
              <figcaption className="figure-caption text-center">
                Natalia Pacheco, founder LatinSalsa
              </figcaption>
            </figure>
          </div>
        </div>
      </Container>
      <div className="mt-5"></div>
      <WhatsApp />
      <LsFamily />
      <Newsletter />
      <BigSocial />
    </Layout>
  )
}
