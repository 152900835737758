import React from "react"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"

export default function LsFamily() {
  return (
    <Container className="container-fluid py-4 text-center">
      <h2 className="display-4">LatinSalsa Family</h2>
      <Row>
        <div className="col-sm-6 col-lg-3 py-4">
          <div className="lc-block mb-4">
            <div>
              <p>
                <a
                  href="https://www.latinsalsa.de"
                  className="fs-3 text-decoration-none"
                  target="_blank"
                  rel="noreferrer"
                  title="Dance Salsa and Bachata in Courses, Workshops or Private Classes in Cologne, Germany"
                >
                  LatinSalsa.de
                </a>
              </p>
              Dance School for Salsa and Bachata in Cologne, Germany.{" "}
              <small>Website in German.</small>
            </div>
          </div>
          <div className="lc-block col-2 offset-5 border-bottom border-2 border-dark">
            <div></div>
          </div>
        </div>

        <div className="col-sm-6 col-lg-3 py-4">
          <div className="lc-block mb-4">
            <div>
              <p>
                <a
                  href="https://www.latinsalsa-jga.de"
                  className="fs-3 text-primary text-decoration-none"
                  target="_blank"
                  rel="noreferrer"
                  title="Book your bachelorette party with Salsa, Bachata and weeding
                choreographies in Cologne, Germany."
                >
                  LatinSalsa-Jga.de
                </a>
              </p>
              <p>
                Bachelorette parties with Salsa, Bachata and weeding
                choreographies in Cologne, Germany.{" "}
                <small>Website in German.</small>
              </p>
            </div>
          </div>
          <div className="lc-block col-2 offset-5 border-bottom border-2 border-dark">
            <div></div>
          </div>
        </div>

        <div className="col-sm-6 col-lg-3 py-4">
          <div className="lc-block mb-4">
            <div>
              <p>
                <a
                  href="http://www.latinsalsa-reisen.de"
                  className="fs-3 text-decoration-none text-primary"
                  target="_blank"
                  rel="noreferrer"
                  title="Discover Costa Rica and Dance Salsa, Bachata, Reggaeton and Cumbia at authentic parties in paradise."
                >
                  LatinSalsa-Reisen.de
                </a>
              </p>
              <p>
                Discover Costa Rica and Dance in Paradise.{" "}
                <small>Website in German.</small>
              </p>
            </div>
          </div>
          <div className="lc-block col-2 offset-5 border-bottom border-2 border-dark">
            <div></div>
          </div>
        </div>

        <div className="col-sm-6 col-lg-3 py-4">
          <div className="lc-block mb-4">
            <div>
              <p>
                <a
                  href="https://www.casalapa.com"
                  className="fs-3 text-decoration-none"
                  target="_blank"
                  rel="noreferrer"
                  title="Book your stay in Costa Rica in Alajuela close to the Santamaria Airpot, San José."
                >
                  Casa Lapa
                </a>
              </p>
              <p>
                Stay close to the San José airport in Costa Rica in one of our
                rooms or in the appartment.
              </p>
            </div>
          </div>
          <div className="lc-block col-2 offset-5 border-bottom border-2 border-dark">
            <div></div>
          </div>
        </div>
      </Row>
    </Container>
  )
}
