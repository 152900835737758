import React from "react"
import Container from "react-bootstrap/Container"
import { SiWhatsapp } from "react-icons/si"
import Cta from "../cta"

export default function WhatsApp() {
  return (
    <div>
      <Container className="text-center">
        <h2 className="display-4">
          <SiWhatsapp /> WhatsApp
        </h2>
      </Container>
      <Cta
        link="https://wa.me/qr/INEASIEOK24RK1"
        btn="Contact Natalia via WhatsApp"
        bgcolor="LightGoldenRodYellow"
      />
    </div>
  )
}
